<template>
    <div>
        <w-data-table
            :loading="loading"
            :title="$trans('Departments')"
            icon="DEPARTMENT"
            :headers="departmentsHeaders"
            :items="items"
            hide-default-header
            hide-default-footer
        >
            <template #item.title="{ item }">
                <div class="d-flex py-2">
                    <div>
                        <div class="font-weight-bold">{{ item.title }}</div>
                        <small class="caption d-none d-md-block">
                            {{ item.school.title }}
                        </small>
                    </div>
                </div>
            </template>

            <template #item.actions="{ item }">
                <v-btn
                    color="error"
                    icon
                    @click="onDetachDepartment(item)"
                >
                    <w-icon value="CLOSE"/>
                </v-btn>
            </template>

            <template #footer>
                <v-btn
                    v-if="!readOnly"
                    :disabled="$auth.isAssistant()"
                    text
                    block
                    tile
                    color="primary"
                    class="py-6"
                    @click="onShowAddForm"
                >
                    <w-icon left value="ADD"/>
                    {{ addDepartmentTitle }}
                </v-btn>
            </template>
        </w-data-table>
        <w-dialog-drawer
            v-model="isDialog"
            :title="addDepartmentTitle"
            width="500"
        >
            <div class="pa-4">
                <v-autocomplete
                    v-model="selectedDepartment"
                    :items="foundDepartments"
                    :loading="isSearchingDepartment"
                    :search-input.sync="searchDepartment"
                    outlined
                    hide-no-data
                    hide-selected
                    clearable
                    hide-details
                    item-text="title"
                    item-value="uuid"
                    :label="$trans('Department')"
                    :placeholder="$trans('Start typing to Search')"
                    :prepend-icon="departmentIcon"
                    @click:clear="foundDepartments = []"
                >
                    <template v-slot:item="{ item, attrs, on }">
                        <v-list-item dense v-bind="attrs" v-on="on" class="my-2">
                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                                <v-list-item-subtitle>{{ item.school.title }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-autocomplete>
                <div class="d-flex mt-4">
                    <v-spacer></v-spacer>
                    <v-btn
                        outlined
                        color="primary"
                        @click="onAttachDepartment"
                    >
                        {{ $trans('Add') }}
                    </v-btn>
                </div>
            </div>
        </w-dialog-drawer>
    </div>
</template>

<script>
import { DEPARTMENT } from '@/widgets/components/WIcon/icons'
import { getDepartmentsCollection } from '@apps/school/api/departments'
import { attachDepartmentToTeacher, detachDepartmentFromTeacher } from '@apps/school/api/teachers'
import ConfirmationSets from '@/utils/ConfirmationSets'
import EventBus from '@/utils/EventBus'
import Debugger from '@/utils/Debugger'

export default {
    name: 'TeacherDepartments',
    props: {
        uuid: {
            type: String,
            required: true
        },
        readOnly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            items: [],
            loading: false,
            isDialog: false,
            isSearchingDepartment: false,
            selectedDepartment: null,
            searchDepartment: '',
            foundDepartments: [],
            departmentIcon: DEPARTMENT
        }
    },
    computed: {
        addDepartmentTitle() {
            return `${this.$trans('Add department')}`
        },
        departmentsHeaders() {
            const headers = [
                {
                    text: this.$trans('Department'),
                    value: 'title'
                }
            ]

            if(!this.readOnly) {
                headers.push({
                    value: 'actions',
                    align: 'right'
                })
            }

            return headers
        }
    },
    watch: {
        searchDepartment(val) {
            if (!val || (val && val.length < 3)) {
                this.isSearchingDepartment = false
                return
            }

            // Items have already been loaded
            if (this.foundDepartments.length > 0) return

            // Items have already been requested
            if (this.isSearchingDepartment) return

            this.isSearchingDepartment = true

            // Lazily load input items
            getDepartmentsCollection({ search: val, with: 'school' })
                .then(response => {
                    this.foundDepartments = response.data.data
                })
                .finally(() => (this.isSearchingDepartment = false))
        }
    },
    async mounted() {
        await this.fetchDepartments()
    },
    methods: {
        async fetchDepartments() {
            if (!this.uuid) {
                return
            }

            try {
                this.loading = true

                const response = await getDepartmentsCollection({
                    teacher: this.uuid,
                    with: 'school'
                })

                this.items = response.data.data
                this.loading = false

                this.$emit('loaded', this.items)
            } catch (e) {
                this.loading = false
            }
        },
        onShowAddForm() {
            this.isDialog = true
            this.foundDepartments = []
            this.selectedDepartment = null
        },
        async onAttachDepartment() {
            if (!this.selectedDepartment) {
                return
            }

            try {
                await attachDepartmentToTeacher(this.uuid, this.selectedDepartment)
                this.isDialog = false
                this.selectedDepartment = null
                this.foundDepartments = []

                await this.fetchDepartments()

                this.$emit('synced')

                EventBus.$emit('teacher-departments-synced')
            } catch (error) {
                Debugger.log(error)
            }
        },
        async onDetachDepartment(item) {
            const isConfirm = await this.$confirm(ConfirmationSets.deleteSet({
                title: `${this.$trans('Delete')} ${item.title}`
            }))

            if (!isConfirm) {
                return
            }

            try {
                await detachDepartmentFromTeacher(this.uuid, item.uuid)
                this.isDialog = false

                await this.fetchDepartments()

                this.$emit('synced')

                EventBus.$emit('teacher-departments-synced')
            } catch (error) {
                Debugger.log(error)
            }
        }
    }
}
</script>

<style lang=scss>

</style>
