var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('w-data-table',{attrs:{"loading":_vm.loading,"title":_vm.$trans('Departments'),"icon":"DEPARTMENT","headers":_vm.departmentsHeaders,"items":_vm.items,"hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex py-2"},[_c('div',[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.title))]),_c('small',{staticClass:"caption d-none d-md-block"},[_vm._v(" "+_vm._s(item.school.title)+" ")])])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.onDetachDepartment(item)}}},[_c('w-icon',{attrs:{"value":"CLOSE"}})],1)]}},{key:"footer",fn:function(){return [(!_vm.readOnly)?_c('v-btn',{staticClass:"py-6",attrs:{"disabled":_vm.$auth.isAssistant(),"text":"","block":"","tile":"","color":"primary"},on:{"click":_vm.onShowAddForm}},[_c('w-icon',{attrs:{"left":"","value":"ADD"}}),_vm._v(" "+_vm._s(_vm.addDepartmentTitle)+" ")],1):_vm._e()]},proxy:true}])}),_c('w-dialog-drawer',{attrs:{"title":_vm.addDepartmentTitle,"width":"500"},model:{value:(_vm.isDialog),callback:function ($$v) {_vm.isDialog=$$v},expression:"isDialog"}},[_c('div',{staticClass:"pa-4"},[_c('v-autocomplete',{attrs:{"items":_vm.foundDepartments,"loading":_vm.isSearchingDepartment,"search-input":_vm.searchDepartment,"outlined":"","hide-no-data":"","hide-selected":"","clearable":"","hide-details":"","item-text":"title","item-value":"uuid","label":_vm.$trans('Department'),"placeholder":_vm.$trans('Start typing to Search'),"prepend-icon":_vm.departmentIcon},on:{"update:searchInput":function($event){_vm.searchDepartment=$event},"update:search-input":function($event){_vm.searchDepartment=$event},"click:clear":function($event){_vm.foundDepartments = []}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"my-2",attrs:{"dense":""}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.school.title))])],1)],1)]}}]),model:{value:(_vm.selectedDepartment),callback:function ($$v) {_vm.selectedDepartment=$$v},expression:"selectedDepartment"}}),_c('div',{staticClass:"d-flex mt-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":_vm.onAttachDepartment}},[_vm._v(" "+_vm._s(_vm.$trans('Add'))+" ")])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }