import request from '@/plugins/request'

const prefix = 'teachers'

export const getTeachersCollection = payload => {
    const options = {
        method: 'GET',
        url: `${prefix}`,
        params: payload
    }

    return request(options)
}

export const getTeacher = uuid => {
    const options = {
        method: 'GET',
        url: `${prefix}/${uuid}`,
    }

    return request(options)
}

/**
 *
 * @param {Object} data
 * @returns {AxiosPromise}
 */
export const storeTeacher = data => {
    const options = {
        method: 'POST',
        url: `${prefix}`,
        data: data
    }

    return request(options)
}

/**
 *
 * @param {String} uuid
 * @param {Object} data
 * @returns {AxiosPromise}
 */
export const updateTeacher = (uuid, data) => {
    const options = {
        method: 'PATCH',
        url: `${prefix}/${uuid}`,
        data: data
    }

    return request(options)
}

/**
 *
 * @param {String} uuid
 * @returns {AxiosPromise}
 */
export const deleteTeacher = uuid => {
    const options = {
        method: 'DELETE',
        url: `${prefix}/${uuid}`
    }

    return request(options)
}

/**
 *
 * @param {String} uuid
 * @param {String} departmentUuid
 * @returns {AxiosPromise}
 */
export const attachDepartmentToTeacher = (uuid, departmentUuid) => {
    const options = {
        method: 'PATCH',
        url: `${prefix}/${uuid}/attach/department`,
        data: { department_uuid: departmentUuid }
    }

    return request(options)
}

/**
 *
 * @param {String} uuid
 * @param {String} departmentUuid
 * @returns {AxiosPromise}
 */
export const detachDepartmentFromTeacher = (uuid, departmentUuid) => {
    const options = {
        method: 'PATCH',
        url: `${prefix}/${uuid}/detach/department`,
        data: { department_uuid: departmentUuid }
    }

    return request(options)
}
